<template>
  <div>
    <div class="content-tab border-top-line mb-5">
      <v-container
        fluid
        style="width: 100%"
      >
        <v-row v-if="loaderSkeleton">
          <v-col cols="12">
            <div>
              <v-sheet
                height="450px"
                color="grey lighten-4"
                class="pa-0"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="table"
                ></v-skeleton-loader>
              </v-sheet>
            </div>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col
            class="pa-0"
            cols="12"
            sm="12"
          >
            <div
              v-if="arrOrders.length > 0"
              class="width-100"
            >
              <div v-if="isResponsive">
                <div
                  v-for="(item, index) in arrOrders"
                  :key="index"
                  class="card-content mb-5"
                >
                  <p
                    style="font-size: 25px;"
                    class="text-left mon-bold"
                  >{{ item.sFolioId }}</p>
                  <div class="display-flex align-items-center">
                    <p class="mon-bold">{{ texts.order.tableFinalized.headers[1].text }}</p>
                    <v-spacer />
                    <p class="text-left mon-regular">{{ item.sEnterpriseName }}</p>
                  </div>
                  <div class="display-flex align-items-center">
                    <p class="mon-bold">{{ texts.order.tableFinalized.headers[2].text }}</p>
                    <v-spacer />
                    <p class="text-left mon-regular">${{ formatMoney(item.dTotalPrice) }} USD</p>
                  </div>
                  <div class="display-flex align-items-center">
                    <p class="mon-bold">{{ texts.order.tableFinalized.headers[3].text }}</p>
                    <v-spacer />
                    <p v-if="item.sCurrentStatusId !== '6069dc17-74c3-4757-b533-2af4a853322d'" class="text-center mon-regular align-items-center">
                      {{
                            (item.oPaymentData.sPaymentMethodId == "3d0c5ce8-2eaf-479a-a49a-8d2c4f48f387")
                            ? item.iBillDays + ' ' + texts.order.textDay + '(s)'
                            : 'N/A'
                          }}
                    </p>
                    <p v-else class="text-center mon-regular align-items-center">
                      Cancelado
                    </p>
                  </div>
                  <div class="display-flex align-items-center">
                    <p class="mon-bold">{{ texts.order.tableFinalized.headers[4].text }}</p>
                    <v-spacer />
                    <p class="text-left mon-regular">{{ item.sPaid }}</p>
                  </div>
                  <div class="display-flex align-items-center">
                    <p class="mon-bold">{{ texts.order.tableFinalized.headers[5].text }}</p>
                    <v-spacer />
                    <p class="text-left mon-regular">{{ item.sCurrentStatusName }}</p>
                  </div>
                  <div class="display-flex align-items-center">
                    <p class="mon-bold">{{ texts.order.tableFinalized.headers[6].text }}</p>
                    <v-spacer />
                    <p
                      v-if="item.sCurrentStatusId == '4616a47e-b7cd-4f98-86c7-4b5a80363111'"
                      class="mon-regular"
                    >
                      N/A
                    </p>
                    <p
                      v-else
                      class="text-left mon-regular"
                    >
                      <label
                        v-if="item.oSalesOrderFile.sUrlFile != null"
                        class="text-link mon-regular"
                        @click="redirect(item.oSalesOrderFile.sUrlFile)"
                      >
                        <!-- {{ item.oPurchaseOrderFile.sPurchaseO⁄rderFileTypeName }} -->
                        {{ item.oSalesOrderFile.sSalesOrderFileTypeName }}
                      </label>
                    </p>
                  </div>
                  <div>
                    <v-btn
                      class="button-primary mon-bold mt-5 mb-2"
                      @click="orderDetail(item.sSalesOrderId)"
                      elevation="0"
                      style="width: 100%;"
                    >
                      {{
                        (selectLanguage == 'sp')
                        ? 'Ver detalle'
                        : 'View detail'
                      }}
                    </v-btn>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="card-content"
              >
                <v-simple-table
                  height="450px"
                  fixed-header
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          v-for="(item, index) in texts.order.tableFinalized.headers"
                          :key="index"
                          :class="`${item.align} text-header-title mon-bold`"
                        >
                          {{ item.text }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in arrOrders"
                        :key="index"
                      >
                        <td class="text-left mon-regular">{{ item.sFolioId }}</td>
                        <td class="text-left mon-regular">{{ item.sEnterpriseName }}</td>
                        <td class="text-left mon-regular">${{ formatMoney(item.dTotalPrice) }} USD</td>
                        <!-- <td class="text-center mon-regular">{{ item.iBillDays }} {{ texts.order.textDay }}(s)</td> -->
                        <td class="text-center mon-regular">
                          
                          <span v-if="item.sCurrentStatusId !== '6069dc17-74c3-4757-b533-2af4a853322d'">
                          {{
                            (item.oPaymentData.sPaymentMethodId == "3d0c5ce8-2eaf-479a-a49a-8d2c4f48f387")
                            ? item.iBillDays + ' ' + texts.order.textDay + '(s)'
                            : 'N/A'
                          }}
                          </span>
                          <span v-else>
                            Cancelado
                          </span>
                        </td>
                        <td class="text-left mon-regular">{{ item.sPaid }}</td>
                        <td class="text-left mon-regular">{{ item.sCurrentStatusName }}</td>
                        <!-- <td
                          v-if="item.sCurrentStatusId == '4616a47e-b7cd-4f98-86c7-4b5a80363111'"
                          class="mon-regular"
                        >
                          N/A
                        </td> -->
                        <td
                          class="text-left mon-regular"
                        >
                          <label
                            v-if="item.oSalesOrderFile.sUrlFile != null"
                            class="text-link mon-regular"
                            @click="redirect(item.oSalesOrderFile.sUrlFile)"
                          >
                            <!-- {{ item.oPurchaseOrderFile.sPurchaseO⁄rderFileTypeName }} -->
                            {{ item.oSalesOrderFile.sSalesOrderFileTypeName }}
                          </label>
                          <span v-else>
                            N/A
                          </span>
                        </td>
                        <td class="text-center">
                          <v-btn
                            icon
                            @click="orderDetail(item.sSalesOrderId)"
                          >
                            <v-icon
                              color="#000"
                              size="16px"
                            >
                              mdi-eye
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div class="display-flex align-items-center justify-content-center mt-4 mb-4">
                  <div class="pagination-styles">
                    <v-pagination
                      v-model="iCurrentPage"
                      :length="lengthPage"
                      color="#FFC556"
                      next-icon="mdi-menu-right"
                      prev-icon="mdi-menu-left"
                    ></v-pagination>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="empty-table display-flex align-items-center justify-content-center"
              v-else
            >
              <p class="mon-regular">{{ texts.order.emptyText }}</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderTabContentTwoLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      //VARIABLES
      iCurrentPage: 1,
      lengthPage: 0,
      arrOrders: [],
      loaderSkeleton: true,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getOrders();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    redirect: function (link) {
      window.open(link, "blank");
    },
    formatDate: function (date) {
      // var new_date = MOMENT(date, "MM/DD/YYYY");
      // var format_date = MOMENT(new_date).format("MMM. DD, YYYY.");
      return date.substring(0, 13);
    },
    formatMoney: function (money) {
      return FORMAT_MONEY(money);
    },
    orderDetail: function (id) {
      this.$router.push({
        path: `/order-detail/finalized/${id}`,
      });
    },
    getOrders: function () {
      this.loaderSkeleton = true;

      DB.get(`${URI}/api/v1/${this.selectLanguage}/sales-orders`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: 10,
          sSearch: this.sSearch,
          iFilter: 2,
          tStart: this.startDate,
          tEnd: this.endDate,
          bPaid: this.paymentStatus,
        },
      })
        .then((response) => {
          this.loaderSkeleton = false;
          this.arrOrders = response.data.results;
          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.loaderSkeleton = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    sSearch: function () {
      return this.$store.state.sSearch;
    },
    startDate() {
      return this.$store.state.startDate;
    },
    endDate() {
      return this.$store.state.endDate;
    },
    refesh: function () {
      return this.$store.state.refesh;
    },
    paymentStatus: function () {
      return this.$store.state.paymentStatus;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getOrders();
      }
    },
    sSearch: lodash.debounce(function (val) {
      this.iCurrentPage = 1;
      this.getOrders();
    }, 500),
    endDate: function () {
      if (this.endDate) {
        this.iCurrentPage = 1;
        this.getOrders();
      }
    },
    startDate: function () {
      this.iCurrentPage = 1;
      this.getOrders();
    },
    paymentStatus: function () {
      this.iCurrentPage = 1;
      this.getOrders();
    },
    iCurrentPage: function () {
      this.getOrders();
    },
    refesh: function () {
      this.getOrders();
    },
  },
};
</script>

<style scoped>
.empty-table {
  background-color: #ffffff;
  border-radius: 10px;
  height: 450px;
}

.empty-table p {
  letter-spacing: 0px;
  color: #6e7d94;
  opacity: 1;
  font-size: 20px;
  margin-bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.text-link {
  color: #0971fb;
}

.text-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.text-header-title {
  color: #000000 !important;
  font-size: 14px !important;
  border-bottom: 1px solid #949494 !important;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.display-flex {
  display: flex;
}

.align-items {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.content-tab {
  padding: 20px 0px;
}

.border-top-line {
  border-top: 1px solid #cecece;
}

.border-bottom-line {
  border-bottom: 1px solid #cecece;
}
</style>